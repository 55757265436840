class Extranet.Presenters.TaxDetailsPresenter

  toHash: (model) ->
    deepCopy = true
    data = $.extend deepCopy, {}, model.toJSON()
    data.uuid = data._id

    # prepare taxCategory
    temp = data.taxCategory
    data.taxCategory = {}
    data.taxCategory[temp] = temp

    # prepare perDurationApplicationType
    temp = data.perDurationApplicationType
    data.perDurationApplicationType = {}
    data.perDurationApplicationType[temp]= temp

    # prepare calculationType
    calculationType = data.calculationType
    data.calculationType = {}
    data.calculationType[calculationType] = calculationType
    if calculationType is 'amount'
      data.taxAmount.amount =
        MathHelper.divide data.taxAmount.amount, 100

    data.isPerBooking = not data.isPerTraveler
    data.currencyOptions = @getCurrencyOptions(data.taxAmount?.currencyCode)

    # prepare taxCode
    temp = data.taxCode
    data.taxCode = {}
    data.taxCode[temp]= temp

    data

  getCurrencyOptions: (selectedCurrencyCode)->
    return [] unless Extranet.ClientConfig?.currencies

    currencies = []
    for currencyObj in Extranet.ClientConfig.currencies
      if selectedCurrencyCode
        isSelected = currencyObj.currencyCode is selectedCurrencyCode
      else
        isSelected = currencyObj.defaultCurrency

      currencies.push
        currencyCode: currencyObj.currencyCode
        isSelected: isSelected

    sortedCurrencies = _.sortBy(currencies, 'currencyCode')
    sortedCurrencies

  getHashToSave: (view)->
    saveAttrs =
      internalName: view.$('#internalName').val()
      name: view.$('#name').val()
      taxCategory: view.$('#taxCategory').val()
      isPerTraveler: view.$("#perTraveler").is(':checked')
      perDurationApplicationType: if view.$("#perNight").is(':checked') then 'per_night' else 'per_stay'
      calculationType: view.$('#calculationType').val()
      amount: @getAmount(view)
      currencyCode: view.$('#currencyCode').val()

    stateUuid= view.$('#tax-information .state-selector').val()
    cityUuid= view.$('#tax-information .city-selector').val()
    if cityUuid then saveAttrs.cityUuid= cityUuid else saveAttrs.stateUuid= stateUuid
    saveAttrs._csrf = $('#csrf_token').val()
    saveAttrs

  getAmount: (view)->
    calculationType = view.$('#calculationType').val()
    amount = view.$('#taxAmount').val()
    amount = +amount unless _.isEmpty amount
    if calculationType is 'amount'
      amount = amount * 100
    else
      amount
