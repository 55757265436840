class Extranet.Presenters.Tax

  PER_NIGHT: 'per_night'
  MERCHANT: 'merchant'
  GROUPON: 'groupon'
  PERCENT: 'percent'
  COLLECT_ON_SITE: 'collectOnsite'

  format: (hash) ->
    uuid: hash.uuid
    name: hash.name
    category: @_getCategory hash
    durationApplication: @_getDurationApplication hash
    guestApplication: @_getGuestApplication hash
    amount: @_getAmount hash
    region: @_getRegion hash
    paymentRecipient: @_getPaymentRecipient hash
  
  _getCategory: (hash) ->
    switch hash.taxCategory
      when @MERCHANT then I18n.translate 'js.modules.profile.taxes.merchant'
      when @GROUPON then I18n.translate 'js.modules.profile.taxes.groupon'
      when @COLLECT_ON_SITE then I18n.translate 'js.modules.profile.taxes.collect_on_site'

  
  _getDurationApplication: (hash) ->
    if hash.perDurationApplicationType is @PER_NIGHT
      I18n.translate 'js.modules.profile.taxes.per_night'
    else 
      I18n.translate 'js.modules.profile.taxes.per_stay'
  
  _getGuestApplication: (hash) ->
    if hash.isPerTraveler
      I18n.translate 'js.modules.profile.taxes.per_traveler'
    else
      I18n.translate 'js.modules.profile.taxes.per_booking'
  
  _getAmount: (hash) ->
    amount = hash.taxAmount?.amount
    if hash.calculationType is @PERCENT
      amount + '%' 
    else
      amount = MathHelper.divide amount, 100 
      I18n.toCurrency amount, hash.taxAmount?.currencyCode

  _getRegion: (hash) ->
    hash.city?.name or hash.state?.name

  _getPaymentRecipient: (hash) ->
    if hash.taxCode? and (hash.name? and hash.name.toLowerCase().includes('tax'))
      I18n.translate 'js.modules.profile.taxes.groupon_financial_authority'
    else
      I18n.translate 'js.modules.profile.taxes.groupon_merchant'
