class Extranet.Helpers.CreateHotel

  @getSaveAttrs: (view) ->
    saveAttrs =
      _csrf: $('#csrf_token').val()

      # Hard coded values
      # Should be reverted after the changes of GETAWAYS-15366
      # ------------------------------------------------------
      totalNumberOfRooms: 0
      rateType: "sellRate"
      languageCode: 'en_US'
      descriptions: [
        {
          locale: 'en_US'
          value: ""
        }
      ]
      phoneNumbers: [
        {
          type: 'us'
          number: view.$('#phone-number').val()
        }
      ]
      faxNumbers: [
        {
          type: 'us'
          number: view.$('#fax-number').val()
        }
      ]
      activeStatus: "inactive"
      # ---------------------------------------

      name: view.$('#hotel-name').val()
      checkInTime: view.$('#check-in-selector').val()
      checkOutTime: view.$('#check-out-selector').val()

      hotelAddress:
        address1: view.$('#street-address').val()
        countryUuid: view.$('#country-selector').val()
        stateUuid: view.$('#state-selector').val()
        cityUuid: view.$('#city-selector').val()
        postalCode: view.$('#postal-code').val()
        latitude: view.$('#latitude').val()
        longitude: view.$('#longitude').val()

      timeZoneId: view.$('#timezone-selector').val()
      rateCurrencyCode: view.$('#currency-selector').val()
      email: view.$('#email-address').val()
      grouponMerchant: [view.$('#groupon-merchant-id').val()]
      creditCardFeeMargin: view.$('#credit-card-fee').val()
      marginPercentage: view.$('#margin-percentage').val()
      externalIdMappings: [
        {
          sourceType: "SalesForce",
          value: view.$('#salesforce-id').val()
        },
        {
          sourceType: "QL2",
          value: view.$('#ql2-id').val()
        },
        {
            sourceType: "TripAdvisor",
            value: view.$('#tripadvisor-id').val()
        }
      ]

    saveAttrs
