class Extranet.Presenters.ChannelManager

  getSettingsHashToDisplay: (hotelModel) ->
    data = hotelModel.toJSON()
    hash =
      channelManagerOptions: @_getChannelManagerOptions(data)
      subChannelManagerOptions: @_getSubChannelManagerOptions(data)
      isChannelManagerConnected: data.channelManager?.isConnectionActive
      isSubChannelManagerConnected: (
        data.channelManager?.isConnectionActive and data.channelManager?.name?.toLowerCase() is Constants.CHANNEL_MANAGERS.TRAVELGATEX and
          data.channelManager?.subChannelManagerName?
      )
    hash

  getMappingHashToDisplay: (channelManagerMappingModel) ->
    hash = channelManagerMappingModel.toJSON()
    {hotel} = hash
    if hotel?.roomTypes?.length
      for roomType in hotel.roomTypes
        if roomType?.ratePlans?.length
          for ratePlan in roomType.ratePlans
            {productSet} = ratePlan

            ratePlan.formattedBonusAdd = @_getBonusAdd(ratePlan)
            ratePlan.url = ExtranetUrlBuilder.getBdRatePlanUrl
              hotelUuid: hotel.uuid
              productSetUuid: productSet.uuid
              ratePlanUuid: ratePlan.uuid

            productSet.statusAbbreviation = Extranet.Helpers.ProductSetStatusHelper.statusAbbreviations[productSet.status]
            productSet.statusTooltipText = productSet.status?.toUpperCase()

            {promotionalWindow} = productSet
            if promotionalWindow?
              promotionalWindow.formattedStartDate = DateTimeHelper.formatDate promotionalWindow.startAt, 'numeric'
              promotionalWindow.formattedEndDate = DateTimeHelper.formatDate promotionalWindow.endAt, 'numeric'

    hash.channelManagerName = channelManagerMappingModel.channelManagerName
    hash.hasRoomTypes = hotel?.roomTypes?.length > 0
    # Only get chainId if the selected Channel Manager is "DerbySoft"
    if channelManagerMappingModel.channelManagerName?.toLowerCase() is Constants.CHANNEL_MANAGERS.DERBYSOFT
      hash.chainId = hotel?.chainId
    if channelManagerMappingModel.channelManagerName?.toLowerCase() is Constants.CHANNEL_MANAGERS.TRAVELGATEX
      hash.requestorId = hotel?.requestorId
      hash.accessId = hotel?.accessId
    hash

  getMappingHashToSave: (view) ->
    channelManagerName = view.$("#connection-name-selector").val()?.trim()?.toLowerCase()
    chainId = null
    requestorId = null
    accessId = null

    # Only get chainId if the selected Channel Manager is "DerbySoft"
    if channelManagerName?.toLowerCase() is Constants.CHANNEL_MANAGERS.DERBYSOFT
      chainId = view.$("#hotel-chain-id").val() or null

    # Only get requestorId and accessId if the selected Channel Manager is "Travelgatex"
    if channelManagerName?.toLowerCase() is Constants.CHANNEL_MANAGERS.TRAVELGATEX
      requestorId = view.$("#hotel-requestor-id").val() or null
      accessId = view.$("#hotel-access-id").val() or null

    roomTypes = []

    # Collecting the uuid and channel manager ids for all the room types
    view.$(".room-types .room-type").each ()->
      roomType =
        uuid: $(this).find('.room-type-uuid').val()
        channelManagerId: $(this).find('.room-type-channel-manager-id').val()
        ratePlans: []

      # Collecting the uuid and channel manager ids for all the rate plans under the room type
      $(this).find(".rate-plans .rate-plan").each ()->
        ratePlan =
          uuid: $(this).find('.rate-plan-uuid').val()
          channelManagerId: $(this).find('.rate-plan-channel-manager-id').val()
          productSetUuid: $(this).find('.product-set-uuid').val()
        roomType.ratePlans.push ratePlan

      roomTypes.push roomType

    hash =
      _csrf: $('#csrf_token').val()
      channelManagerId: view.$("#hotel-channel-manager-id").val()
      chainId: chainId  # Include only when DerbySoft is selected
      requestorId: requestorId  # Include only when Travelgatex is selected
      accessId: accessId  # Include only when Travelgatex is selected
      roomTypes: roomTypes

    hash
  
  shouldDisplayMappingInformation: (channelManagerName) ->
    channelManager = _.find Extranet.ClientConfig.channelManagers, (channelManager) -> channelManager.name is channelManagerName
    channelManager? and not channelManager.hideMappingInformation

  _getChannelManagerOptions: (data) ->
    # The supported channel managers list is maintained in GConfig
    channelManagers = Extranet.ClientConfig.channelManagers
    return [] unless channelManagers
    channelManagerOptions = _.map channelManagers, (hash) ->
      name: hash.name
      isSelected: hash.name is data.channelManager?.name
    channelManagerOptions

  _getSubChannelManagerOptions: (data) ->
    # The supported channel managers list is maintained in GConfig
    subChannelManagers = Extranet.ClientConfig.subChannelManagers
    return [] unless subChannelManagers
    subChannelManagerOptions = _.map subChannelManagers, (hash) ->
      name: hash.name
      value: hash.value
      isSubChannelMangerSelected: data.channelManager?.name?.toLowerCase() is Constants.CHANNEL_MANAGERS.TRAVELGATEX and data.channelManager?.subChannelManagerName is hash.value
    subChannelManagerOptions

  _getBonusAdd: (ratePlan) ->
    return null unless ratePlan?.bonusAdd?.value
    {amount, currencyCode} = ratePlan?.bonusAdd?.value
    if amount? and currencyCode?
      amount = amount / 100
      return I18n.toCurrency(amount, currencyCode)
    else
      return null