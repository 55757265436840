# This file serves to provide constants that can be shared between backend and frontend

Constants =
  ROLE:
    ADMIN: 'admin'
    ADMIN_READONLY: 'admin_readonly'
    HOTELIER: 'hotelier'
  INVENTORY_MANAGEMENT_MODE:
    ROOM_TYPE: 'roomType'
    RATE_PLAN: 'ratePlan'
  PRODUCT_TYPE:
    BASE: 'base'
    BOOKING_DEAL: 'bookingDeal'
  PRODUCT_SET_STATUS:
    CREATED: 'created'
    SHOP_RATES: 'shop-rates'
    READY: 'ready'
    REVIEW_VIOLATIONS: 'review-violations'
    APPROVED: 'approved'
    LIVE: 'live'
    FINISHED: 'finished'
  NOT_APPLICABLE: 'N/A'
  CHANNEL_MANAGERS:
    DERBYSOFT: 'derbysoft'
    TRAVELGATEX: 'travelgatex'

if window?
  window.Constants = Constants
else
  module.exports = Constants